<template>
  <div class="body">
    <div class="home_title2" />
    <div class="w">
      <div class="login_div">
        <div class="login_title">用户登录</div>
        <el-form ref="loginForm" :model="formData" :rules="loginRules" class="demo-ruleForm">
          <el-form-item label="账号/邮箱" size="login" prop="userName" label-position="top" style="margin-bottom:10px">
            <el-input v-model.trim="formData.userName" />
          </el-form-item>
          <el-form-item label="请输入密码" size="login" label-position="top" prop="password" style="margin-bottom:10px">
            <el-input :type="showPass ? 'text' : 'password'" v-model.trim="formData.password" />
            <i :class="showPass ? 'open' : 'close'" style="position: absolute; right: 6px; top: 41px;width:22px;height:22px;" @click="showPass = !showPass"></i>
          </el-form-item>
          <el-form-item prop="checked" size="login" style="margin-bottom:10px">
            <div style="text-align: left;">
              <el-checkbox v-model.trim="formData.checked">在计算机上保留我的信息</el-checkbox>
            </div>
          </el-form-item>
          <el-form-item size="login">
            <el-button type="primary" @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <div class="footer_title">
          <div class="logo_bm">北京北大方正电子有限公司提供技术支持</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Login, GetPublicKey, GetAesKey } from '@/api/login'
import { mapActions } from 'vuex'
import { Asencrypt, Rsencrypt } from '@/utils'

export default {
  name: 'Login',
  data() {
    return {
      formData: {
        userName: '',
        password: '',
        checked: true
      },
      publicKey: '', // 公钥
      aesKey: '',
      loginRules: {
        userName: [{ required: true, trigger: 'blur', message: '请输入账号或邮箱' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
      },
      showPass: false
    }
  },
  mounted() {
    window.sessionStorage.clear()
    this.resetAll()
  },
  methods: {
    ...mapActions(['resetAll']),
    login() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          const publicPromise = this.getPublicKeyFun()
          const aesKeyPromise = this.getAesKeyFun()
          Promise.all([publicPromise, aesKeyPromise]).then((res) => {
            const [res1, res2] = res
            if (res1 && res2) {
              let params = {
                loginName: Asencrypt(this.formData.userName, this.aesKey),
                password: Rsencrypt(this.formData.password, this.publicKey)
              }
              Login(params).then((resp) => {
                let { data, status } = resp.data
                if (status !== 0 && status !== -2) {
                  this.$message.error(resp.data.message)
                } else {
                  // 如果勾选了记住密码，在localstorage中存用户信息
                  if (this.formData.checked === true) {
                    let userinfo = {
                      loginName: this.formData.userName,
                      password: this.formData.password,
                      checked: true
                    }
                    localStorage.setItem('userinfo', JSON.stringify(userinfo))
                  } else {
                    localStorage.removeItem('userinfo')
                  }
                  sessionStorage.setItem('user', JSON.stringify(data.member))
                  sessionStorage.setItem('role', data.member.role)
                  sessionStorage.setItem('token', data.token)
                  sessionStorage.setItem('realName', data.member.realName)
                  // 0:普通  1:超级管理员 2:管理员  3:销售  4:设计 5:服务 6:采集 7:数据中台 8:资源中心 9:产品（管理）
                  if (data.member.role === 3) {
                    this.$router.push('/sales').catch((err) => {
                      err
                    })
                  } else if (data.member.role === 4) {
                    this.$router.push('/designer').catch((err) => {
                      err
                    })
                  } else if (data.member.role === 5 ||data.member.role === 6 || data.member.role === 7 ||data.member.role === 8 ||data.member.role === 9) {
                    this.$router.push('/collection').catch((err) => {
                      err
                    })
                  } else {
                    this.$router.push('/analysis').catch((err) => {
                      err
                    })
                  }
                }
              })
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取publicKey
    getPublicKeyFun() {
      return GetPublicKey().then((resp) => {
        let { data, status, message = '' } = resp.data
        if (status === 0) {
          this.publicKey = data.publicKey
          return true
        } else {
          this.$message.error(message)
          return false
        }
        // if (status !== 0) {
        //   // 数据获取失败
        //   this.$message({
        //     type: 'error',
        //     message: '数据获取失败'
        //   })
        //   return false
        // }
      })
    },
    // 获取AES的key
    getAesKeyFun() {
      return GetAesKey().then((resp) => {
        let { data, status, message = '' } = resp.data
        if (status === 0) {
          this.aesKey = data.aesKey
          return true
        } else {
          this.$message.error(message)
          return false
        }
        // if (status !== 0) {
        //   // 数据获取失败
        //   this.$message({
        //     type: 'error',
        //     message: '数据获取失败'
        //   })
        //   return false
        // }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.body {
  padding-top: 172px;
  box-sizing: border-box;
}
.home_title2 {
  width: 1200px;
  margin: 0 auto;
  background-position: 50%;
  height: 120px;
  line-height: 120px;
  text-align: left;
}
.login_div {
  width: 500px;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 50px;
  background: rgba(255, 255, 255, 0.07);
  margin-bottom: 20px;
}
.login_title {
  font-size: 24px;
  color: #fff;
  line-height: 44px;
  position: relative;
}
.login_title::before {
  content: ' ';
  width: 40px;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -20px;
  background: #fff;
  border-radius: 30px;
}
.demo-ruleForm {
  padding-top: 30px;
}
.err {
  position: absolute;
  bottom: 15px;
  left: 50px;
  color: #ec5463;
  font-size: 14px;
}
.footer {
  height: 60px;
  width: 100%;
}
.footer_title {
  font-size: 14px;
  color: #a9b2e9;
  line-height: 60px;
}
.open {
  background: url('../../../public/imgs/open.png') center center no-repeat;
}
.close {
  background: url('../../../public/imgs/close.png') center center no-repeat;
}
</style>
